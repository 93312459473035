/**
 * Banner provides a container with a title, a button, and an image.
 * Supported props: title subtitle startstudy banner
 */
import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { css } from '../util/pagetools';
import Safe from './Safe';

export default function Banner(props) {
  const cfg = props.src;

  return (
    <div id="banner" className={css('row banner', cfg.banner.theme)}>
      <div className="col-xs-12 col-sm-6">
        <div className="banner-left-content">
          <Safe className="banner-title" type="h1" content={cfg.title} />
          <Safe className="banner-text" content={cfg.subtitle} />
        </div>

      </div>
      <div className="col-xs-12 col-sm-6 banner-img-container">
        <StaticImage src='../images/banner.jpg' alt="main banner" layout="fullWidth" className="hidden-xs" />
        <StaticImage src='../images/banner_mobile.jpg' alt="main banner" layout="fullWidth" className="visible-xs" />
      </div>
    </div>
  );
};
